.marker-wrapp{
    top: 40%;
    left: 50%;

    position: absolute;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
