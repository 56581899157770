
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
  }
  
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }
  
  .col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }
  
  .col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
  }
  
  .col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
  }
  
  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }
  
  .col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }
  
  .col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
  }
  
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }
  
  .col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
  }
  
  .col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }
  
  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }
  
  .col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
  }
  
  .col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
  }
  
  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }
  
  .order-first {
    -ms-flex-order: -1;
    order: -1
  }
  
  .order-last {
    -ms-flex-order: 13;
    order: 13
  }
  
  .order-0 {
    -ms-flex-order: 0;
    order: 0
  }
  
  .order-1 {
    -ms-flex-order: 1;
    order: 1
  }
  
  .order-2 {
    -ms-flex-order: 2;
    order: 2
  }
  
  .order-3 {
    -ms-flex-order: 3;
    order: 3
  }
  
  .order-4 {
    -ms-flex-order: 4;
    order: 4
  }
  
  .order-5 {
    -ms-flex-order: 5;
    order: 5
  }
  
  .order-6 {
    -ms-flex-order: 6;
    order: 6
  }
  
  .order-7 {
    -ms-flex-order: 7;
    order: 7
  }
  
  .order-8 {
    -ms-flex-order: 8;
    order: 8
  }
  
  .order-9 {
    -ms-flex-order: 9;
    order: 9
  }
  
  .order-10 {
    -ms-flex-order: 10;
    order: 10
  }
  
  .order-11 {
    -ms-flex-order: 11;
    order: 11
  }
  
  .order-12 {
    -ms-flex-order: 12;
    order: 12
  }
  
  .offset-1 {
    margin-left: 8.333333%
  }
  
  .offset-2 {
    margin-left: 16.666667%
  }
  
  .offset-3 {
    margin-left: 25%
  }
  
  .offset-4 {
    margin-left: 33.333333%
  }
  
  .offset-5 {
    margin-left: 41.666667%
  }
  
  .offset-6 {
    margin-left: 50%
  }
  
  .offset-7 {
    margin-left: 58.333333%
  }
  
  .offset-8 {
    margin-left: 66.666667%
  }
  
  .offset-9 {
    margin-left: 75%
  }
  
  .offset-10 {
    margin-left: 83.333333%
  }
  
  .offset-11 {
    margin-left: 91.666667%
  }
  
  @media (min-width:576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
  
    .col-sm-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
  
    .col-sm-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
    }
  
    .col-sm-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
    }
  
    .col-sm-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
  
    .col-sm-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }
  
    .col-sm-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
    }
  
    .col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
  
    .col-sm-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
    }
  
    .col-sm-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }
  
    .col-sm-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
  
    .col-sm-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
    }
  
    .col-sm-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
    }
  
    .col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
  
    .order-sm-first {
      -ms-flex-order: -1;
      order: -1
    }
  
    .order-sm-last {
      -ms-flex-order: 13;
      order: 13
    }
  
    .order-sm-0 {
      -ms-flex-order: 0;
      order: 0
    }
  
    .order-sm-1 {
      -ms-flex-order: 1;
      order: 1
    }
  
    .order-sm-2 {
      -ms-flex-order: 2;
      order: 2
    }
  
    .order-sm-3 {
      -ms-flex-order: 3;
      order: 3
    }
  
    .order-sm-4 {
      -ms-flex-order: 4;
      order: 4
    }
  
    .order-sm-5 {
      -ms-flex-order: 5;
      order: 5
    }
  
    .order-sm-6 {
      -ms-flex-order: 6;
      order: 6
    }
  
    .order-sm-7 {
      -ms-flex-order: 7;
      order: 7
    }
  
    .order-sm-8 {
      -ms-flex-order: 8;
      order: 8
    }
  
    .order-sm-9 {
      -ms-flex-order: 9;
      order: 9
    }
  
    .order-sm-10 {
      -ms-flex-order: 10;
      order: 10
    }
  
    .order-sm-11 {
      -ms-flex-order: 11;
      order: 11
    }
  
    .order-sm-12 {
      -ms-flex-order: 12;
      order: 12
    }
  
    .offset-sm-0 {
      margin-left: 0
    }
  
    .offset-sm-1 {
      margin-left: 8.333333%
    }
  
    .offset-sm-2 {
      margin-left: 16.666667%
    }
  
    .offset-sm-3 {
      margin-left: 25%
    }
  
    .offset-sm-4 {
      margin-left: 33.333333%
    }
  
    .offset-sm-5 {
      margin-left: 41.666667%
    }
  
    .offset-sm-6 {
      margin-left: 50%
    }
  
    .offset-sm-7 {
      margin-left: 58.333333%
    }
  
    .offset-sm-8 {
      margin-left: 66.666667%
    }
  
    .offset-sm-9 {
      margin-left: 75%
    }
  
    .offset-sm-10 {
      margin-left: 83.333333%
    }
  
    .offset-sm-11 {
      margin-left: 91.666667%
    }
  }
  
  @media (min-width:768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
  
    .col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
  
    .col-md-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
    }
  
    .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
    }
  
    .col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
  
    .col-md-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }
  
    .col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
    }
  
    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
  
    .col-md-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
    }
  
    .col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }
  
    .col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
  
    .col-md-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
    }
  
    .col-md-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
    }
  
    .col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
  
    .order-md-first {
      -ms-flex-order: -1;
      order: -1
    }
  
    .order-md-last {
      -ms-flex-order: 13;
      order: 13
    }
  
    .order-md-0 {
      -ms-flex-order: 0;
      order: 0
    }
  
    .order-md-1 {
      -ms-flex-order: 1;
      order: 1
    }
  
    .order-md-2 {
      -ms-flex-order: 2;
      order: 2
    }
  
    .order-md-3 {
      -ms-flex-order: 3;
      order: 3
    }
  
    .order-md-4 {
      -ms-flex-order: 4;
      order: 4
    }
  
    .order-md-5 {
      -ms-flex-order: 5;
      order: 5
    }
  
    .order-md-6 {
      -ms-flex-order: 6;
      order: 6
    }
  
    .order-md-7 {
      -ms-flex-order: 7;
      order: 7
    }
  
    .order-md-8 {
      -ms-flex-order: 8;
      order: 8
    }
  
    .order-md-9 {
      -ms-flex-order: 9;
      order: 9
    }
  
    .order-md-10 {
      -ms-flex-order: 10;
      order: 10
    }
  
    .order-md-11 {
      -ms-flex-order: 11;
      order: 11
    }
  
    .order-md-12 {
      -ms-flex-order: 12;
      order: 12
    }
  
    .offset-md-0 {
      margin-left: 0
    }
  
    .offset-md-1 {
      margin-left: 8.333333%
    }
  
    .offset-md-2 {
      margin-left: 16.666667%
    }
  
    .offset-md-3 {
      margin-left: 25%
    }
  
    .offset-md-4 {
      margin-left: 33.333333%
    }
  
    .offset-md-5 {
      margin-left: 41.666667%
    }
  
    .offset-md-6 {
      margin-left: 50%
    }
  
    .offset-md-7 {
      margin-left: 58.333333%
    }
  
    .offset-md-8 {
      margin-left: 66.666667%
    }
  
    .offset-md-9 {
      margin-left: 75%
    }
  
    .offset-md-10 {
      margin-left: 83.333333%
    }
  
    .offset-md-11 {
      margin-left: 91.666667%
    }
  }
  
  @media (min-width:992px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
  
    .col-lg-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
  
    .col-lg-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
    }
  
    .col-lg-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
    }
  
    .col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
  
    .col-lg-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }
  
    .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
    }
  
    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
  
    .col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
    }
  
    .col-lg-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }
  
    .col-lg-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
  
    .col-lg-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
    }
  
    .col-lg-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
    }
  
    .col-lg-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
  
    .order-lg-first {
      -ms-flex-order: -1;
      order: -1
    }
  
    .order-lg-last {
      -ms-flex-order: 13;
      order: 13
    }
  
    .order-lg-0 {
      -ms-flex-order: 0;
      order: 0
    }
  
    .order-lg-1 {
      -ms-flex-order: 1;
      order: 1
    }
  
    .order-lg-2 {
      -ms-flex-order: 2;
      order: 2
    }
  
    .order-lg-3 {
      -ms-flex-order: 3;
      order: 3
    }
  
    .order-lg-4 {
      -ms-flex-order: 4;
      order: 4
    }
  
    .order-lg-5 {
      -ms-flex-order: 5;
      order: 5
    }
  
    .order-lg-6 {
      -ms-flex-order: 6;
      order: 6
    }
  
    .order-lg-7 {
      -ms-flex-order: 7;
      order: 7
    }
  
    .order-lg-8 {
      -ms-flex-order: 8;
      order: 8
    }
  
    .order-lg-9 {
      -ms-flex-order: 9;
      order: 9
    }
  
    .order-lg-10 {
      -ms-flex-order: 10;
      order: 10
    }
  
    .order-lg-11 {
      -ms-flex-order: 11;
      order: 11
    }
  
    .order-lg-12 {
      -ms-flex-order: 12;
      order: 12
    }
  
    .offset-lg-0 {
      margin-left: 0
    }
  
    .offset-lg-1 {
      margin-left: 8.333333%
    }
  
    .offset-lg-2 {
      margin-left: 16.666667%
    }
  
    .offset-lg-3 {
      margin-left: 25%
    }
  
    .offset-lg-4 {
      margin-left: 33.333333%
    }
  
    .offset-lg-5 {
      margin-left: 41.666667%
    }
  
    .offset-lg-6 {
      margin-left: 50%
    }
  
    .offset-lg-7 {
      margin-left: 58.333333%
    }
  
    .offset-lg-8 {
      margin-left: 66.666667%
    }
  
    .offset-lg-9 {
      margin-left: 75%
    }
  
    .offset-lg-10 {
      margin-left: 83.333333%
    }
  
    .offset-lg-11 {
      margin-left: 91.666667%
    }
  }
  
  @media (min-width:1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
  
    .col-xl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
  
    .col-xl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%
    }
  
    .col-xl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%
    }
  
    .col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
  
    .col-xl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%
    }
  
    .col-xl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%
    }
  
    .col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
  
    .col-xl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%
    }
  
    .col-xl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%
    }
  
    .col-xl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
  
    .col-xl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%
    }
  
    .col-xl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%
    }
  
    .col-xl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
  
    .order-xl-first {
      -ms-flex-order: -1;
      order: -1
    }
  
    .order-xl-last {
      -ms-flex-order: 13;
      order: 13
    }
  
    .order-xl-0 {
      -ms-flex-order: 0;
      order: 0
    }
  
    .order-xl-1 {
      -ms-flex-order: 1;
      order: 1
    }
  
    .order-xl-2 {
      -ms-flex-order: 2;
      order: 2
    }
  
    .order-xl-3 {
      -ms-flex-order: 3;
      order: 3
    }
  
    .order-xl-4 {
      -ms-flex-order: 4;
      order: 4
    }
  
    .order-xl-5 {
      -ms-flex-order: 5;
      order: 5
    }
  
    .order-xl-6 {
      -ms-flex-order: 6;
      order: 6
    }
  
    .order-xl-7 {
      -ms-flex-order: 7;
      order: 7
    }
  
    .order-xl-8 {
      -ms-flex-order: 8;
      order: 8
    }
  
    .order-xl-9 {
      -ms-flex-order: 9;
      order: 9
    }
  
    .order-xl-10 {
      -ms-flex-order: 10;
      order: 10
    }
  
    .order-xl-11 {
      -ms-flex-order: 11;
      order: 11
    }
  
    .order-xl-12 {
      -ms-flex-order: 12;
      order: 12
    }
  
    .offset-xl-0 {
      margin-left: 0
    }
  
    .offset-xl-1 {
      margin-left: 8.333333%
    }
  
    .offset-xl-2 {
      margin-left: 16.666667%
    }
  
    .offset-xl-3 {
      margin-left: 25%
    }
  
    .offset-xl-4 {
      margin-left: 33.333333%
    }
  
    .offset-xl-5 {
      margin-left: 41.666667%
    }
  
    .offset-xl-6 {
      margin-left: 50%
    }
  
    .offset-xl-7 {
      margin-left: 58.333333%
    }
  
    .offset-xl-8 {
      margin-left: 66.666667%
    }
  
    .offset-xl-9 {
      margin-left: 75%
    }
  
    .offset-xl-10 {
      margin-left: 83.333333%
    }
  
    .offset-xl-11 {
      margin-left: 91.666667%
    }
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
  }
  
  @media (min-width:576px) {
    .container {
      max-width: 540px
    }
  }
  
  @media (min-width:768px) {
    .container {
      max-width: 720px;
      
    }
  }
  
  @media (min-width:992px) {
    .container {
      max-width: 960px
    }
  }
  
  @media (min-width:1200px) {
    .container {
      max-width: 1140px
    }
  }
  
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
  }
  

  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
  }
  
  .card>hr {
    margin-right: 0;
    margin-left: 0
  }
  
  .card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
  }
  
  .card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
  }
  
  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem
  }
  
  .card-title {
    margin-bottom: .75rem
  }
  
  .card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
  }
  
  .card-text:last-child {
    margin-bottom: 0
  }
  
  .card-link:hover {
    text-decoration: none
  }
  
  .card-link+.card-link {
    margin-left: 1.25rem
  }
  
  .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
  }
  
  .card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
  }
  
  .card-header+.list-group .list-group-item:first-child {
    border-top: 0
  }
  
  .card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
  }
  
  .card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
  }
  
  .card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
  }
  
  .card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
  }
  
  .card-img {
    width: 100%;
    border-radius: calc(.25rem - 1px)
  }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
  }
  
  .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
  }
  
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
  }
  
  .card-deck .card {
    margin-bottom: 15px
  }
  
  @media (min-width:576px) {
    .card-deck {
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px
    }
  
    .card-deck .card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px
    }
  }
  
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
  }
  
  .card-group>.card {
    margin-bottom: 15px
  }
  
  @media (min-width:576px) {
    .card-group {
      -ms-flex-flow: row wrap;
      flex-flow: row wrap
    }
  
    .card-group>.card {
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0
    }
  
    .card-group>.card+.card {
      margin-left: 0;
      border-left: 0
    }
  
    .card-group>.card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0
    }
  
    .card-group>.card:not(:last-child) .card-header, .card-group>.card:not(:last-child) .card-img-top {
      border-top-right-radius: 0
    }
  
    .card-group>.card:not(:last-child) .card-footer, .card-group>.card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0
    }
  
    .card-group>.card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0
    }
  
    .card-group>.card:not(:first-child) .card-header, .card-group>.card:not(:first-child) .card-img-top {
      border-top-left-radius: 0
    }
  
    .card-group>.card:not(:first-child) .card-footer, .card-group>.card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0
    }
  }
  
  .card-columns .card {
    margin-bottom: .75rem
  }
  
  @media (min-width:576px) {
    .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1
    }
  
    .card-columns .card {
      display: inline-block;
      width: 100%
    }
  }
  
  .accordion>.card {
    overflow: hidden
  }
  
  .accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0
  }
  
  .accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0
  }
  
  .accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }
  
  .accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }
  
  .accordion>.card .card-header {
    margin-bottom: -1px
  }
  

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
      transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

  .btn-primary:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
  }

  .btn-primary:focus, .btn-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }

  .btn-primary.disabled, .btn-primary:disabled {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
  }

  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf;
  }

      .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-primary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

  .btn-secondary:hover {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
  }

  .btn-secondary:focus, .btn-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }

  .btn-secondary.disabled, .btn-secondary:disabled {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
      color: #fff;
      background-color: #545b62;
      border-color: #4e555b;
  }

      .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-secondary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

  .btn-success:hover {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
  }

  .btn-success:focus, .btn-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }

  .btn-success.disabled, .btn-success:disabled {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
  }

  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
      color: #fff;
      background-color: #1e7e34;
      border-color: #1c7430;
  }

      .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
      .show > .btn-success.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

  .btn-info:hover {
      color: #fff;
      background-color: #138496;
      border-color: #117a8b;
  }

  .btn-info:focus, .btn-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }

  .btn-info.disabled, .btn-info:disabled {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
  }

  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
      color: #fff;
      background-color: #117a8b;
      border-color: #10707f;
  }

      .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn-info.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
      }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

  .btn-warning:hover {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
  }

  .btn-warning:focus, .btn-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }

  .btn-warning.disabled, .btn-warning:disabled {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107;
  }

  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
      color: #212529;
      background-color: #d39e00;
      border-color: #c69500;
  }

      .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > .btn-warning.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
      }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

  .btn-danger:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
  }

  .btn-danger:focus, .btn-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }

  .btn-danger.disabled, .btn-danger:disabled {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
  }

  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
      color: #fff;
      background-color: #bd2130;
      border-color: #b21f2d;
  }

      .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > .btn-danger.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

  .btn-light:hover {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5;
  }

  .btn-light:focus, .btn-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }

  .btn-light.disabled, .btn-light:disabled {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
  }

  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
      color: #212529;
      background-color: #dae0e5;
      border-color: #d3d9df;
  }

      .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
      .show > .btn-light.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

  .btn-dark:hover {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124;
  }

  .btn-dark:focus, .btn-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }

  .btn-dark.disabled, .btn-dark:disabled {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40;
  }

  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
      color: #fff;
      background-color: #1d2124;
      border-color: #171a1d;
  }

      .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
      .show > .btn-dark.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

  .btn-outline-primary:hover {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
  }

  .btn-outline-primary:focus, .btn-outline-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }

  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
      color: #007bff;
      background-color: transparent;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
  }

      .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-primary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

  .btn-outline-secondary:hover {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
  }

  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }

  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
      color: #6c757d;
      background-color: transparent;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
  }

      .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-secondary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

  .btn-outline-success:hover {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
  }

  .btn-outline-success:focus, .btn-outline-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }

  .btn-outline-success.disabled, .btn-outline-success:disabled {
      color: #28a745;
      background-color: transparent;
  }

  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
  }

      .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-success.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

  .btn-outline-info:hover {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
  }

  .btn-outline-info:focus, .btn-outline-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }

  .btn-outline-info.disabled, .btn-outline-info:disabled {
      color: #17a2b8;
      background-color: transparent;
  }

  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
      color: #fff;
      background-color: #17a2b8;
      border-color: #17a2b8;
  }

      .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-info.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
      }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

  .btn-outline-warning:hover {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107;
  }

  .btn-outline-warning:focus, .btn-outline-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }

  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
      color: #ffc107;
      background-color: transparent;
  }

  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107;
  }

      .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-warning.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
      }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

  .btn-outline-danger:hover {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
  }

  .btn-outline-danger:focus, .btn-outline-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }

  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
      color: #dc3545;
      background-color: transparent;
  }

  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;
  }

      .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-danger.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

  .btn-outline-light:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
  }

  .btn-outline-light:focus, .btn-outline-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }

  .btn-outline-light.disabled, .btn-outline-light:disabled {
      color: #f8f9fa;
      background-color: transparent;
  }

  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
  }

      .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-light.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

  .btn-outline-dark:hover {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40;
  }

  .btn-outline-dark:focus, .btn-outline-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }

  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
      color: #343a40;
      background-color: transparent;
  }

  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40;
  }

      .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
      .show > .btn-outline-dark.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

  .btn-link:hover {
      color: #0056b3;
      text-decoration: underline;
      background-color: transparent;
      border-color: transparent;
  }

  .btn-link:focus, .btn-link.focus {
      text-decoration: underline;
      border-color: transparent;
      box-shadow: none;
  }

  .btn-link:disabled, .btn-link.disabled {
      color: #6c757d;
      pointer-events: none;
  }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

  .btn-block + .btn-block {
      margin-top: 0.5rem;
  }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
  }
  
  @media (prefers-reduced-motion:reduce) {
    .form-control {
      transition: none
    }
  }
  
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0
  }
  
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
  }
  
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
  }
  
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
  }
  
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
  }
  
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
  }
  
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1
  }
  
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
  }
  
  select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff
  }
  
  .form-control-file, .form-control-range {
    display: block;
    width: 100%
  }
  
  .col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
  }
  
  .col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5
  }
  
  .col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem;
    line-height: 1.5
  }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
  }
  
  .form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
  }
  
  .form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
  }
  
  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
  }
  
  select.form-control[multiple], select.form-control[size] {
    height: auto
  }
  
  textarea.form-control {
    height: auto
  }
  
  .form-group {
    margin-bottom: 1rem
  }
  
  .form-text {
    display: block;
    margin-top: .25rem
  }
  
  .form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
  }
  
  .form-row>.col, .form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
  }
  
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
  }
  
  .form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
  }
  
  .form-check-input:disabled~.form-check-label {
    color: #6c757d
  }
  
  .form-check-label {
    margin-bottom: 0
  }
  
  .form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
  }
  
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
  }
  
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745
  }
  
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, .9);
    border-radius: .25rem
  }
  
  .form-control.is-valid, .was-validated .form-control:valid {
    border-color: #28a745;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
  }
  
  .form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
  }
  
  .form-control.is-valid~.valid-feedback, .form-control.is-valid~.valid-tooltip, .was-validated .form-control:valid~.valid-feedback, .was-validated .form-control:valid~.valid-tooltip {
    display: block
  }