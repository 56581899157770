ul.breadcrumb {
    padding: 10px 10px;
    margin: 30px 50px 10px 10px;
    list-style: none;
    background-color: #eee;
  }
  
  /* Display list items side by side */
  ul.breadcrumb li {
    display: inline;
    font-size: 18px;
  }
  
  /* Add a slash symbol (/) before/behind each list item */
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  
  /* Add a color to all links inside the list */
  ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
    font-size: 14px;
  }
  
  /* Add a color on mouse-over */
  ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
  }