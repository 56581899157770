
.actionMenuWrapp{
    display: flex;
    flex-direction: column;
    width: 10px;
    background: #ffffff;
    height: 21px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.actionMenuWrapp:hover{
    background: #adadad;
}
.ActionMenuChild{
    display: block;
    flex: 1;
    width: 4px;
    height: 5px;
    margin: 1px;
    background: #adadad;
    border: 1px solid #9c9c9c;
    border-radius: 2px;

}
.actionMenuWrapp:hover > .ActionMenuChild{
    background: black;
    border: 1px solid black;
}
.actionMenuLinks{
    background: white;
    border: 1px solid #dadada;
    width: 150px;
    padding: 4px;
    /* min-heigh t: 50px; */
    position: absolute;
    top: 127px;
    left: 0;
    box-shadow: 0px 0px 10px -4px #000;
}
.actionMenuLinks > .link{
    border-bottom: 1px solid #cccccc36;
    color: #333333;
    cursor: pointer;   
}
.actionMenuLinks > .link:hover{
    color: black;
    background: #f8f8f8;
}
.actionMenuHiddenWrapp{
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 201;
}