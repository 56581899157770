/* import the font from Google */
/* @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap"); */
/* default css */
.html,
.body {
  font-family: 'HacenTunisiaBdRegular';
  overflow-x: hidden;
  background-image: url('../images/log-cover.jpg');
   background-repeat: no-repeat;
   background-size: cover;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h2 , h4 , .text-center{
  text-align: center;
  color: #17284d
}
/********************************/
/*logibn page */
.login-parent{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #00000081;
}
.form-wrap {
  height: auto;
  background-color: transparent;
  width: 35%;
}
.form-wrap  .form{
  background-color:rgb(240, 254, 255);
  padding: 23px 44px 50px 32px;
  border-radius: 7px;
  position: relative;
}
.form-wrap h4{
  text-align: center;
  padding: 10px 0 60px 0;
  font-weight: 700;
}
.form-wrap .field{
  display: flex;
  border-radius: 4px;
  box-shadow: 4px 2px 10px -1px rgba(129, 129, 129, 0.349);
  background-color: #ffffff;
  margin-bottom: 20px;
  overflow: hidden;
}
.form-wrap .field i{
  color: gray;
  padding: 14px;
}
.form-wrap .field input{
  border: 0;
  padding: 13px 10px 13px 15px;
  outline: none;
  font-size: 15px;
  width: 100%;
  font-family: 'HacenTunisiaBdRegular';
}
.form-wrap .field input:focus{
  outline: none;
}
.form-wrap .field input:placeholder-shown,
.form-wrap .field textarea:placeholder-shown,
label span{
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  color: gray;
  font-family: 'HacenTunisiaBdRegular';
}
.form-wrap .password-strength{
  padding: 0 0 20px 0;
}
.form-wrap .password-strength p{
  font-weight: 700;
  font-size: 14px;
}
.form-wrap .password-strength p span{
  color: #0ca7c2;
}
.form-wrap .lower{
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  position: relative;
}
.form-wrap .lower a{
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: gray;
}

/** checkbox **/
input[type="checkbox"] {
  visibility: hidden;
}
.checkboxOverride {
  position: relative;
  width: 20px;
}
.checkboxOverride input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.checkboxOverride label {
  background-color: #ffffff;
  cursor: pointer;
  height: 25px;
  right: 0;
  position: absolute;
  top: 0;
  width: 25px;
  border-radius: 4px;
  box-shadow: 4px 2px 10px -1px rgba(129, 129, 129, 0.349);
  display: flex;
}
.checkboxOverride label:after {
  border-style: none none solid solid;
  content: "";
  height: 5px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  -ms-transform:rotate(-45deg); /* IE 9 */
  -webkit-transform:rotate(-45deg); /* Safari and Chrome */
  transform:rotate(-45deg);
  width: 10px;
}
.checkboxOverride label span{
  position: absolute;
  right: 35px;
  top: 5px;
  display: flex;
  user-select: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -s-user-select: none;
}
.checkboxOverride label.label-text-2 span{
  width: 200px !important;
}
.checkboxOverride label span .colored-p{
  color: #11cdef;
  margin-right: 5px;
  margin-top: 0;
}
.form-wrap  .text-center{
  padding: 10px 0 0 0;
}
.form-wrap  .text-center a{
  border-radius: 7px;
  box-shadow: 0px 20px 30px rgba(16, 101, 175, 0.151);
  background-color: #ffffff;
  margin: auto;
  padding: 15px 44px;
  background-color: #11cdef;
  color: #fff;
  text-align: center;
  border:0;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  transition: 400ms;
  text-decoration: none;
}
.form-wrap  .text-center a:hover{
  background-color: #0ca7c2;
}
.form-wrap .text-padding{
  text-align: center;
  padding: 20px 0 0 0;
}
.form-wrap .text-padding a.new{
   color: #fff;
   text-decoration: none;
   font-weight: 700;
}
.form-wrap .text-padding a.new span{
  color: #11cdef;
}
@media(max-width:1200px){
  .form-wrap{
    width: 60%;
  }
}
@media(max-width:800px){
  .form-wrap{
    width: 80%;
  }
}
@media(max-width:568px) and (max-width:400px){
  .form-wrap{
    width: 92%;
  }
  .form-wrap h2{
    font-size: 22px;
  }
}

