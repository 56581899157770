.te-card{
    background: white;
    margin: 5px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px -3px;
}
.te-card-title{
    font-weight: bold;
    margin: auto 10px;
}
.card-img-top{
    min-height: 167px;
}
.te-card-img {
    height: 180px
}
.list-item{
    padding: 5px
}

/******* card with icon and title **************/
.folder-card {
    width: 150px;
    margin: 8px auto;
    flex-direction: column;
    border: 1px solid #eee;
    padding: 15px 0px 0px 5px;
}
.image-card a i {
    text-align: center;
    display: block;
}
.folder-card .folder-title {
    text-align: center;
    margin: 10px 4px;;
}