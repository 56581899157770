h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "HacenTunisiaBdRegular";
    margin: 0;
}


.cardRow {
    display: flex;
    flex-wrap: wrap;
}

.cardItem {
    width: 105px;
    text-align:start;
    
    border: solid 1px rgb(206, 191, 191);
    padding: 5px;
}
.cardItem2 {
    width: 70px;
    text-align:center;
    
    border: solid 1px rgb(206, 191, 191);

    background-color: rgb(224 232 249);
    
    padding: 5px;
}

.foter {
    background-color: red;
}

.add-btn {
    background: #17284d;
    text-align: center;
    margin: 0px;
    padding: 6px;
    padding-top: 20px;
    margin-bottom: 29px;
    margin-top: -21px;
}

.menu-show {
    display: block;
    height: 88%;
}

/* .menu-hide{
    display: none;
} */

@media (max-width: 800px) {
    .menu-hide {
        display: none;
    }

    #sideBarLinksItems {
        /* display: none; */
        z-index: 44;
        height: 87%;
    }

    .sidenav .top:not(.active) {
        background: none;
        display: 'none';
    }

    .sidenav .top:not(.active) .lines .line {
        background: white;
    }
}

.ui * {
    text-align: right;
}

.ui.popup .content {
    overflow-y: hidden;
}

.actions-wrapp {
    opacity: 0;
    position: absolute;
    background: #ffffffe6;
    padding: 5px;
    top: 10px;
    left: 10px;
    transition: all .2s;
}

.actions-wrapp .edit,
.actions-wrapp .remove {
    transition: all .2s;
    cursor: pointer;
}

.actions-wrapp .edit {
    color: green;
}

.actions-wrapp .edit:hover {
    font-size: 20px;
}

.actions-wrapp .remove {
    color: red;
}

.actions-wrapp .remove:hover {
    font-size: 20px;
    transform: rotate(360deg);
}

.actions:hover .actions-wrapp {
    opacity: 1;
}

.phone-number {
    background: #e6e6e6;
    padding: 5px 17px;
    margin: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    box-shadow: 0px 0px 8px -6px;
}

.te-card.links:hover {
    background: #f7f7f7;
}

.te-card.links.not-readed {
    background: #d4e2ff;
}

.show-msg.list-item>div {
    margin: 10px auto;
}

#sideBarLinksItems {
    height: 87.6%;
    overflow-y: auto;
}

/* width */

::-webkit-scrollbar {
    width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #75829e;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #4c5b79;
    border-radius: 30px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #17284d;
}

.icon {
    cursor: pointer;
}

.icon:hover {}

.filterNo {
    width: 100px;
}

.filterDate {
    width: 150px;
    display: inline-block;
}

.filter-item {
    padding: 10px;
}

.filterBtCont {
    /* text-align: left; */
}


.filterCont {
    border: solid 1px silver;
    margin: 5px;
    margin-bottom: 20px;
    padding: 10px
}